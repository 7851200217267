<template lang="pug">
.-home
  h1 TaiwanGenomes
  .-form
    p Search variants by genomic position, rsID, gene name, clinical signature or their combinations.  One may leave the search field empty to #[a(@click='viewAll') browser all variants].
    .ui.right.action.left.icon.fluid.input
      i.search.icon
      input(v-model='input' type='text' @keyup.enter='search' ref='queryInput')
      .ui.button.-right.-apply(@click='search')
        .text Search
    p
    | Search samples:
    .-samples
      div(v-for="sample in samples") #[a(@click='replaceQuery(sample.query)' href='#') {{ sample.query }}]#[br]({{ sample.description }})
</template>

<script>
import 'semantic-ui-offline/semantic.css'
import axios from 'axios'
import Swal from 'sweetalert2'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters(['filters', 'columns']),
    ...mapState(['isMainCreated'])
  },

  data: () => ({
    input: '',
    samples: [
      { query: 'chr1:10000-20000', description: 'gene position' },
      { query: 'rs201752861', description: 'rsID' },
      { query: 'BRCA1 BRCA2', description: 'gene name' },
      {
        query: 'pathogenic likely_pathogenic',
        description: 'clinical signature'
      }
    ]
  }),

  mounted() {
    this.$refs.queryInput.focus()
  },

  methods: {
    ...mapMutations([
      'setSelectedCohortIdx',
      'setCohortSamples',
      'resetFilters'
    ]),
    ...mapActions(['me']),

    replaceQuery(query) {
      this.input = query
      this.$refs.queryInput.focus()
    },

    isShown(column) {
      return this.columns[column].filterable && this.columns[column].selected
    },

    async viewAll() {
      this.resetFilters()
      let isMainCreated = this.isMainCreated //!
      await this.$router.push('main')
      if (isMainCreated) {
        this.$root.$emit('Filter:apply', '')
      }
    },

    async search() {
      this.resetFilters()
      try {
        await this.parse(this.input)
      } catch (err) {
        return
      }
      let isMainCreated = this.isMainCreated //!
      await this.$router.push('main')
      if (isMainCreated) {
        this.$root.$emit('Filter:apply', '')
      }
    },

    async parse(input) {
      const querys = input.trim().split(/[,\ ]/)
      for (let query of querys) {
        query = query.trim()
        if (query == '' || ['at', 'in', 'on', 'of'].includes(query)) continue
        try {
          var filterName = await this.classify(query)
        } catch (err) {
          const isConfirmed = await this.confirmNoMatch(query)
          if (isConfirmed) continue
          throw 'query no match filterNmae'
        }

        let filter = this.filters[filterName]

        if (filter.contain) {
          //! document and exmaples
          if (query.match(/^[c,g,p]\.\d*[\+,\-]?\d*[A,T,C,G]\>[A,T,C,G]$/)) {
            let queryDisassembled = query.split('')
            queryDisassembled.splice(
              query.indexOf('.') + 1,
              0,
              query[query.indexOf('>') - 1]
            )
            queryDisassembled.splice(queryDisassembled.indexOf('>') - 1, 2)
            query = queryDisassembled.join('')
          }
          filter.contain.push(query.trim())
          filter.filtered = true
        } else if (filter.position) {
          filter.position.push(query.trim())
          filter.filtered = true
        }
      }
    },

    async classify(query) {
      //!
      let filterName = null

      //! document and exampleas
      if (query.match(/^[c,g,p]\./)) {
        filterName = 'AAChange.refGene'
      } else if (query.match(/(\S+):(\d+)(-(\d+))?/)) {
        filterName = 'POS'
      } else if (query.match(/[r|R][s|S]\d+/)) {
        filterName = 'ID'
      } else if (await this.isTerms('CLNSIG', query)) {
        filterName = 'CLNSIG'
      } else {
        filterName = 'Gene.refGene'
      }

      return filterName
    },

    async isTerms(field, query) {
      let response = await axios.post(`/exists/${field}/`, { term: query })
      return response.data == 'True'
    },
    async confirmNoMatch(query) {
      const result = await Swal.fire({
        title: `'${query}' has no match field`,
        text: 'Do you want to continue?',
        showDenyButton: true
      })
      return result.isConfirmed
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

.-home
  display: flex
  flex-direction: column
  height: 100vh
  justify-content: center

h1
  text-align: center
  font-size: 6rem
  font-weight: normal
  line-height: 1.5

  @media screen and (max-width: 768px)
    font-size: 3rem

.-form
  color: $DARK-COLOR-3
  font-size: 1.2rem
  line-height: 1.5
  max-width: 40em
  margin: 0 auto
  flex-basis: 18em

.-apply
  background-color: $DARK-COLOR-3
  color: $LIGHT-COLOR-3

  &:hover
    background-color: $DARK-COLOR-1
    color: $LIGHT-COLOR-1

.-samples
  display: flex
  font-size: 1rem

  >div
    margin-right: 2em
</style>
